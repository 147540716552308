export const environment = {
  production: true,
  api: {
    url: 'https://apis.auckland.ac.nz/password-management/v1',
  },
  epr: {
    path: 'https://iam.auckland.ac.nz',
  },
  auth: {
    codeChallengeMethod: 'S256',
    cognitoAwsRegion: 'ap-southeast-2',
    cognitoClientId: 'pnnlarolahhf4n8stvvsfrlbe',
    cognitoDomain: 'uoapool',
    cognitoUserPoolId: 'ap-southeast-2_B3Lx9B4bL',
    scopes: 'openid profile',
    redirectUri: 'https://password.auckland.ac.nz',
    logoutUri: 'https://password.auckland.ac.nz',
  },
  privateUrlKeyWords: {
    whoNeedBearerToken: [{url:'apis.auckland.ac.nz/password-management/v1', optional:true, publicPaths:['/validate', '/reset']}],
  },
};
